
.dropdown-container-component {
    font-family: 'Inter', sans-serif !important;
    font-size: 13px;
    position: relative;
    display: inline-block;
    padding-bottom: 0px;
  }
  
  .dropdown-title-component {
    font-weight: 550;
    margin-bottom: 5px;
    margin-top: 0px;
    padding-top: 0px;
  }
  
  .dropdown-trigger-component {
    border-radius: 5px;
    height: 2rem;
    width: 16rem;
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    justify-content: space-between;
  }
  
  .dropdown-trigger-text-component {
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
  }

  
.dropdown-menu-component {
    position: absolute;
    top: calc(100%);
    left: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex !important;
    flex-direction: column;
    max-height: 8rem; /* Set maximum height to 8rem */
    overflow: auto;
    flex-grow: 1;
  }

  .menuItem {
    padding: 10px;
    /*width: 16rem;*/
    cursor: pointer;
    border-bottom: 1px solid #eee;
    font-size: 12px;
  }
  
  .menuItem:hover {
    background-color: #f0f0f0;
  }
  
  .menuItem:last-child {
    border-bottom: none;
  }