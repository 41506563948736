/* TableSkeleton.css */

.skeleton-header,
.skeleton-header-cell,
.skeleton-cell {
  background-color: #ddd;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite linear;
}

.skeleton-header {
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-block {
  height: 35px;
  width: 20%;
  margin-bottom: 5px;
  border-width: 6px !important;
}

/* .skeleton-header-cell {
    height: 20px;
    width: 32%;
    margin-bottom: 5px;
} */

/* .skeleton-cell {
    height: 30px;
    width: calc(33.33% - 5px);
    margin-bottom: 5px;
    padding: 5px;
} */

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}
