@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/*@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';*/

#root {
  --primary: #1f2937;
  --primaryAccent: #374151;
  --bgAccent: #f9fafb;
  --bgGray: #f3f4f6;
  --blue: #0284c7;
  --blueAccent: #e7f2f8;
  --white: #ffffff;
  --textPrimary: #111827;
  --textSecondary: #515a68;
  --textTertiary: #6b7280;
  --tableHead: #4b5563;

  --navBarColor: #cacccf;
  --navBarText: #1c1c1c;

  --pie1: #e0f2fe;
  --pie2: #075985;
  --pie3: #0284c7;
  --pie4: #0c4a6e;
  --pie5: #7dd3fc;

  --green: #00ac69;
  --red: #ff1616;
  --lighterRed: #ffa7a7;
  --gray: #9e9e9e;
  --vio: #800dad;
  --btn-avtaar-round: #659ce0;
  --btn-avtaar-round-color: #212121;
  --btn-primary: #35313e;
  --btn-secondary: #0074c1;
  --btn-tertiary: #009400;
  --btn-tertiary-hover: #108000;
  --btn-secondary-hover: #005c99;
  --head-main-label: #03a9f4;
  --header-main: #34495e;
  --header-secondary: #03a9f4;
  --radius: 4px;
  --buttonShadow: 1px 1px 1px #f0f0f0;
  --txtPlaceholder: #848484;
  --txtSecondary: #3d3d3d;
  --body-background-color: #e5f4ff;
}

body {
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Inter', sans-serif !important;
  font-size: 13px !important;
  overflow: hidden;
  background-color: #f9fafb !important;
  padding-top: 0px !important;
  height: 100vh;
}

@media print {
  body {
    overflow: visible !important;
  }

  .content-section {
    max-height: none !important;
    overflow: visible !important;
  }

  .page-break {
    page-break-before: always;
  }

  /* Hide non-essential elements for printing */
  .non-printable {
    display: none !important;
  }
}

html {
  background-color: #ececec !important;
}

.main-block::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
.text-area-scrollbar::-webkit-scrollbar,
.edit-form-pdf .rpv-core__inner-pages::-webkit-scrollbar,
.table-row-ellipse .table-container-fund::-webkit-scrollbar,
.fund-compare-table::-webkit-scrollbar,
.feesScroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.main-block::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
.text-area-scrollbar::-webkit-scrollbar-track,
.edit-form-pdf .rpv-core__inner-pages::-webkit-scrollbar-track,
.table-row-ellipse .table-container-fund::-webkit-scrollbar-track,
.fund-compare-table::-webkit-scrollbar-track,
.feesScroll::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 2px;
}

.main-block::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
.text-area-scrollbar::-webkit-scrollbar-thumb,
.edit-form-pdf .rpv-core__inner-pages::-webkit-scrollbar-thumb,
.table-row-ellipse .table-container-fund::-webkit-scrollbar-thumb,
.fund-compare-table::-webkit-scrollbar-thumb,
.feesScroll::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 2px;
}

.main-block::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:hover,
.text-area-scrollbar::-webkit-scrollbar-thumb:hover,
.edit-form-pdf .rpv-core__inner-pages::-webkit-scrollbar-thumb:hover,
.table-row-ellipse .table-container-fund::-webkit-scrollbar-thumb:hover,
.fund-compare-table::-webkit-scrollbar-thumb:hover,
.feesScroll::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(121, 121, 121);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(121, 121, 121);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(121, 121, 121);
}

.indent {
  margin-left: 3.5rem;
}

.smaller-indent {
  margin-left: 1rem;
}

.homeCard {
  background-color: #add8e6;
  border-radius: 4px;
  min-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.main-title {
  font-size: 22px;
  margin: 15px 0 0 0;
  color: #222;
}

.text-center {
  text-align: center;
}

.btn-position {
  display: flex;
  flex-direction: row-reverse;
}

.show-more-btn {
  font-size: 11px !important;
}

.text-20 {
  font-size: 23px;
}

.blue {
  background-color: var(--blueAccent);
}

.circles-container {
  display: flex;
  gap: 5px;
}

.circle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  font-size: small;
  transition: color 0.4s ease;
  user-select: none;
}

.circle.active {
  background-color: #3498db;
  /* Blue color for active */
  color: var(--bgGray);
}

.circle.inactive {
  color: var(--blue);
}

.non-clickable {
  cursor: auto;
}

.pagination-arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  font-size: small;
  transition: color 0.4s ease;
  color: var(--txtSecondary);
}

.pagination-arrow.active {}

.pagination-arrow.inactive {
  color: var(--txtPlaceholder);
}

.oval {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 100px;
  width: auto;
  height: auto;
  padding: 3px;
  font-size: small;
  transition: color 0.4s ease;
}

.oval.active {
  background-color: #3498db;
  /* Blue color for active */
  color: var(--bgGray);
}

.oval.inactive {
  color: var(--blue);
}

.home-icn {
  color: #fff;
  background: #08485c;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
}

.forgot-password {
  color: var(--btn-secondary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 480;
  cursor: pointer;
  transition:
    color 0.3s,
    text-decoration 0.3s;
}

.forgot-password:hover,
.forgot-password:focus {
  color: var(--btn-secondary-hover);
  text-decoration: underline;
}

.homeCard h2 {
  font-size: 22px;
  color: #003546;
}

@media screen and (max-width: 1023px) {}

@media screen and (max-width: 1140px) {}

@media screen and (max-width: 600px) {}

.txtFlex {
  display: flex;
}

.txtSmall {
  font-size: small;
}

.txtMedium {
  font-size: medium;
}

.txtLarge {
  font-size: large;
}

.txt200 {
  font-weight: 200;
}

.txt300 {
  font-weight: 300;
}

.txt400 {
  font-weight: 400;
}

.txt500 {
  font-weight: 500;
}

.txt600 {
  font-weight: 600;
}

.txt700 {
  font-weight: 700;
}

.popover-wrap>div:first-child {
  /* z-index: 100 !important; */
  z-index: 99999999999 !important;
}

.drawer-title-wrap {
  display: flex;
  justify-content: space-between;
}

.drawer-title {
  font-size: 16px;
  margin: 10px 0 10px 18px;
  color: #1c1c1c;
}

.drawer-subtitle {
  font-size: 12px;
  margin: 10px 0 10px 18px;
  color: #1c1c1c;
}

.drawer-close {
  position: absolute;
  right: 15px;
  color: #545454;
  background: #fff;
  border-radius: 5px 0px 0px 5px;
  top: 5px;
  width: 32px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}

.drawer-close svg {
  font-size: 28px;
  margin-left: 4.5px;
}

.drawer-form-wrp {
  padding-left: 1rem;
  padding-right: 1rem;
  height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
}

.drawer-form-wrp label {
  font-weight: 500;
  font-size: 13px;
}

.bank-drawer nav {
  width: 45rem !important;
  top: 55px !important;
  height: auto;
}

.ai-chat-drawer nav {
  width: 509px !important;
  top: 55px !important;
  height: auto;
  overflow-y: scroll;
}

.confirm-buttons {
  display: flex;
  justify-content: center;
  font-size: 13px;
  border-radius: 3px;
}

.confirm-buttons button {
  width: 80px;
  margin: 0 9px 0 0;
}

.delete-close {
  font-size: 33px;
  margin: 0;
  position: absolute;
  right: 4px;
  top: 3px;
  cursor: pointer;
}

.ic-cross {
  font-size: 50px;
  display: block;
  color: #f44336;
  margin: 0 auto;
  border: 2px solid #f44336;
  border-radius: 100%;
  padding: 9px;
}

.ic-info {
  font-size: 55px;
  display: block;
  color: #e99803;
  margin: 0 auto;
}

.ic-success {
  font-size: 55px;
  display: block;
  color: #009400;
  margin: 0 auto;
}

.delete-msg {
  font-size: 15px;
  text-align: center;
  color: #484848;
  margin: 0 0 20px 0px;
}

.delete-title {
  text-align: center;
  margin: 8px 0 10px;
  font-size: 24px;
}

.input-disable {
  pointer-events: none !important;
  opacity: 0.6;
}

.avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.searchTab {
  width: 330px;
}

.search-block {
  position: relative;
  width: 100%;
  margin: -15px;
}

.search-block svg {
  position: absolute;
  top: 11px;
  left: 11px;
  color: #c5c5c5;
}

.search-block input {
  outline: none;
  border: 1px solid var(--primaryAccent);
  padding: 7px 20px 7px 30px;
  border-radius: 4px;
  background-color: var(--primaryAccent);
  color: #c5c5c5;
  box-shadow: none !important;
  width: 100%;
}

.header-wrap {
  background-color: var(--primary);
  height: 55px;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 0 5px 0px 15px;
  z-index: 999;
}

.header-web-wrap {
  background-color: var(--primary);
  height: 55px;
  width: 100%;
  padding: 0 5px 0px 15px;
  position: relative;
}

.text-14 {
  color: #6b7280;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
}

.text-14-executive {
  color: #6b7280;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.web-typography-recommendation {
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 5px;
  text-align: left;
}

.web-typography {
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 5px;
  text-align: left;
}

.web-typography-value {
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 800;
  line-height: 5px;
  text-align: left;
}

.web-typography-mini-executive {
  margin-top: -10px;
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 5px;
  text-align: left;
}

.web-typography-mini {
  margin-top: -10px;
  color: #6b7280;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 5px;
  text-align: left;
}

.web-span-recommendation {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #111827;
}

.web-span {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #111827;
}

.web-span-mini-14 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #111827;
}

.web-span-mini {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #111827;
}

.fund-wrap-content {
  gap: 20px;
  background-color: var(--white);
  border-radius: 8px 8px 8px 8px;
  height: 98%;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 1px solid #e5e7eb !important;
  /* height: 350px; */
  /* height: calc(100vh - 350px); */
  padding: 0px, 0px, 0px, 20px;
  opacity: 1;
}

/* .fund-wrap-content-new {
  gap: 20px;
  background-color: var(--white);
  border-radius: 8px 8px 8px 8px;
  height: 98%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 0px #0000000D;
  border: 1px solid #E5E7EB !important;
  /* height: 350px; */
/* height: calc(100vh - 350px); */
/* width: 50%; */
/* padding: 0px, 0px, 0px, 20px; */
/* opacity: 1; */
/* } */

.fund-wrap-content-new {
  gap: 20px;
  background-color: var(--white);
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 1px solid #e5e7eb !important;
  width: 100%;
  padding: 0px 20px;
  opacity: 1;
}

.split-section {
  column-count: 2;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.split-section .section-half {
  display: inline-block;
  width: 100%;
}

.fund-wrap-web {
  gap: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: var(--bgGray) !important;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 1px solid #e5e7eb !important;
}

.recomandation-content {
  padding: 12px;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  opacity: 1;
  background: #f9fafb;
}

.web-header {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
}

.web-header-scoring {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
}

.flex-web {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-web-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
}

/* 
.header-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  opacity: 1;
} */

.header-action button {
  background-color: transparent;
  color: var(--white);
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
  font-weight: bold;
  margin-left: 8px;
  background-color: var(--blue);
}

.header-action button.reject-btn {
  background-color: var(--red);
}

.web-title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  color: #d1d5db;
  margin: 0;
}

.web-content-header {
  display: flex;
  /* height: 40px; */
  padding: 8px 16px 0px 24px;
  gap: 0px;
  justify-content: space-between;
  opacity: 1;
  align-items: center;
}

.web-content-mini {
  width: 50%;
  /* height: 32px; */
  gap: 0px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  /* line-height: 32px; */
  text-align: center;
  color: #111827;
}

.web-content {
  width: 50%;
  /* height: 32px; */
  gap: 0px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  /* line-height: 32px; */
  text-align: center;
  color: #111827;
}

.main-wrap {
  padding-top: 55px;
  background-color: var(--white);
  height: 100vh;
}

.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  align-items: center;
}

.menu div {
  color: #b9b9b9;
  margin: 10px 10px;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.menuActive {
  background: black;
  color: var(--white) !important;
  transition: all 0.5;
}

.mainLogo {
  height: 50px;
  width: auto;
  margin: 0 0px;
  position: relative;
  left: -10px;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.flex-spaced {
  display: flex;
  align-items: center;
}

.leftSide-flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

.box-pie-Active {
  background-color: var(--bgGray);
  text-align: center;
  border-radius: 10px 10px 1px 1px;
  border-bottom: 4px solid #0284c7;
  margin: 0px 7px 0px 0px;
  cursor: pointer;
}

.box-pie {
  background-color: var(--bgGray);
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  border-bottom: 4px solid #f4f5f7;
  margin: 0px 7px 0px 0px;
  cursor: pointer;
}

.rw {
  margin: 0px !important;
}

.grp-lbl {
  font-size: 13px;
  font-weight: 500;
  color: black;
  padding: 13px 0px;
}

#pieFirst>div {
  display: contents;
}

.buttonStyle {
  height: 37px;
  width: 100%;
  background-color: var(--primary);
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--buttonShadow);
  cursor: pointer;
  border: none;
  color: white;
}

.btn-disabled {
  cursor: initial;
  opacity: 0.7;
}

.txtStyle {
  font-size: var(--F4);
  color: var(--primaryColor);
  font-weight: var(--FW5);
}

.mainHeader {
  background-color: var(--primaryColor);
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px;
}

.alignCEN {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  margin-right: 0;
}

.middle {
  margin-left: auto;
  margin-right: auto;
}

.errorStyle {
  width: fit-content;
  background-color: #fff;
  border: 1px solid var(--red);
  border-radius: var(--radius);
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.3rem;
  font-size: var(--F4);
  color: var(--red);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
}

.errorStyle::before {
  content: '⚠️';
  margin-right: 0.3rem;
}

.inputStyle {
  height: 50px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d3d9de;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.65rem 1rem;
  font-size: var(--F4);
}

.pwd-box-wrapper {
  position: relative;
  width: 100%;
}

.pwdIcon {
  position: absolute;
  right: calc(0% + 1.5rem);
  cursor: pointer;
  z-index: 9999;
}

.mobileStyle {
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d3d9de;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 85px;
  font-size: var(--F4);
}

.dateInput {
  text-transform: uppercase;
  justify-content: flex-start;
}

.inputStyle:focus-visible {
  outline: none !important;
}

.mobileStyle:focus-visible {
  outline: none !important;
}

.inputStyle::placeholder {
  color: var(--txtPlaceholder);
  font-size: 13px;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

.mobileStyle::placeholder {
  color: var(--txtPlaceholder);
  font-size: 13px;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

.lblTxt {
  color: #3d3d3d;
  font-weight: var(--FW3);
  font-size: 13px;
}

.infTxt {
  color: #3d3d3d;
  font-size: 11px;
  margin-top: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.logWarp {
  width: 400px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 35px 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 12px 0px lightgray;
}

.lg-head {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  color: var(--primary);
}

.inptHight {
  height: auto;
}

.loader {
  border: 6px solid #ffffff;
  border-top: 6px solid #0284c7;
  border-radius: 50%;
  width: 70.5px;
  height: 70.3px;
  animation: spinloader 1s linear infinite;
  background: #202938;
}

.lod-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #00000045;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  overflow-x: hidden;
  height: 150vh;
}

.load-container {
  position: relative;
}

.lod-img {
  height: 31px;
  width: auto;
  position: absolute;
  top: 20px;
  right: 18px;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.btn-disable-pop {
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  left: -5px;
  padding-right: 12px;
}

.sub-header-wrap {
  box-shadow: 1px 1px 4px 0px lightgray;
  width: 100%;
  border-bottom: 1px solid lightgray;
  position: relative;
  z-index: 12;
}

.sub-header-wrap .flex:first-child {
  width: 100%;
  justify-content: space-between;
  padding: 10px 17px;
  background: white;
}

.bg-main {
  background-color: var(--bgAccent);
}

.lbl {
  font-size: 14px;
  font-weight: 500;
  color: var(--textPrimary);
}

.smallTxt {
  font-size: 14px;
}

.blueBtn {
  background-color: var(--blue);
  color: white;
  margin: 0 5px;
  width: auto;
  transition: all 0.5s;
  border: none;
  height: 33px;
}

.box-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.box {
  border: solid 1px var(--bgGray);
  width: 50%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title2 {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.content li {
  margin-bottom: 5px;
}

.blueBtn:hover {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
}

.sub-header-wrap .dashBtn {
  background-color: var(--blue);
  color: white;
  margin: 0 5px;
  width: auto;
  transition: all 0.5s;
}

.sub-header-wrap .dashBtn:hover {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
}

.sub-header-wrap .dashBtn:hover .txtBtn {
  color: #111827;
}

.sub-header-wrap .dashBtn:hover svg>path {
  stroke: white;
  fill: none;
}

.sub-header-wrap .dashBtn:hover svg>path {
  fill: var(--primary);
  stroke: var(--primary);
}

.sub-header-wrap .dashBtn-fund svg>path {
  fill: #6b7280;
}

.sub-header-wrap .logo-img {
  fill: #000;
}

.sub-header-wrap .txtBtn {
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 0px 5px;
  position: relative;
  top: 1px;
}

.sub-header-wrap .dashBtn svg {
  font-size: 18px;
  color: white;
  fill: white;
  stroke: white;
}

.dashBtn {
  background-color: var(--blue);
  color: white;
  margin: 0 5px;
  width: 137px;
  transition: all 0.5s;
}

.dashBtn:hover {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
}

.dashBtn:hover .txtBtn {
  color: #111827;
}

.dashBtn:hover svg>path {
  fill: white;
}

.dashBtn-doc:hover svg>path {
  stroke: white;
  fill: none;
}

.dashBtn:hover svg>path {
  fill: var(--primary);
  stroke: var(--primary);
}

.dashBtn-fund svg>path {
  fill: #6b7280;
}

.notesBtn {
  background-color: var(--blue);
  color: white;
  margin: 0 5px;
  width: 5rem;
  transition: all 0.5s;
}

.notesBtn:hover {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
}

.notesBtn:hover .txtBtn {
  color: #111827;
}

.notesBtn:hover svg>path {
  fill: var(--primary);
  stroke: var(--primary);
}

.notesBtn-doc:hover svg>path {
  stroke: white;
  fill: none;
}

.notesBtn-fund svg>path {
  fill: #6b7280;
}

.logo-img {
  fill: #000;
}

.txtBtn {
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 0px 5px;
  position: relative;
  top: 1px;
}

.dashBtn svg {
  font-size: 18px;
  color: white;
  fill: white;
  stroke: white;
}

.dashBtn .txtBtn {
  min-width: 90px;
}

.iconStyle svg {
  font-size: 15px !important;
  color: var(--textSecondary);
  height: 18px;
}

.profTxt {
  text-align: center;
  font-size: 13px;
  color: #1f2937;
  font-weight: 500;
  border-bottom: 1px solid #d3d3d369;
  margin-bottom: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.profTxtLst {
  text-align: center;
  font-size: 13px;
  color: #1f2937;
  font-weight: 500;
  cursor: pointer;
}

.fileUploader {
  border: 2px dashed rgb(111 111 111) !important;
  height: 16rem !important;
  width: 41rem !important;
  margin: 11px !important;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.fileWrap {
  align-items: center;
  justify-content: center;
  width: 41rem;
  text-align: center;
}

.fileWrap svg {
  font-size: 45px;
  margin: 35px;
  color: #727272;
}

.fileTxt {
  margin: 6px 0px;
  font-size: 14px;
  font-weight: 500;
}

.fileSubTxt {
  font-size: 12px;
  color: #797979;
}

.progressBar {
  margin: 8px 0px;
}

.progressBar div {
  height: 6px !important;
}

.progressBar>div>div {
  background: #10b981 !important;
}

.flexBTW {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.spaceBetween {
  justify-content: space-between;
}

.barWidth {
  width: 31rem;
  flex-shrink: 0;
}

.smallerBarWidth {
  width: 21rem;
  flex-shrink: 0;
}

.notesDropdown {
  width: 100%;
  flex-shrink: 0;
  height: 35px;
  background-color: var(--white);
  border: 1px solid #d3d9de;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.note-row {
  position: relative;
  width: 100%;
}

.note-row-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownWidth {
  width: 9rem;
  flex-shrink: 0;
}

.drawerModal {}

.modal-footer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.modal-input {
  width: 70%;
}

.modal-button {
  width: 10%;
  padding: 10px;
  background-color: var(--blueAccent);
  color: var(--btn-avtaar-round-color);
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: lightcyan;
  /* Highlight color when hovering */
}

.modal-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  /* Optional: Add focus outline for accessibility */
}

.modal-spacing {
  width: 5%;
}

.empty-spacing {
  padding: 30px;
}

.upload-box {
  background: #f9fafb;
  padding: 1rem 0.8rem 1rem;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  margin: 13px 10px 20px 10px;
}

.upload-box-title {
  padding: 0rem;
  margin: 13px 10px 6px 10px;
}

.uploadTxt {
  font-size: 11px;
  font-weight: 500;
  color: #374151;
  padding: 0px 5px;
}

.uploadMainBtn {
  background-color: #0074c1;
  width: 41rem;
  margin: 9px;
}

.filterBtn {
  background-color: var(--white);
  border: 1px solid #e5e7eb;
  color: var(--textPrimary);
  margin: 0 5px;
  width: 95px;
  transition: all 0.5s;
}

.filLbl {
  font-size: 13px;
  color: #1f2937;
  font-weight: 500;
  cursor: pointer;
}

.filterMainBtn {
  background-color: #0074c1;
  width: 100%;
  margin: 5px 0px;
  border-radius: 4px;
}

.filterWrap {
  width: 250px !important;
}

.react-select-container-custom {
  border: 1px solid #d3d9de;
  border-radius: 4px;
}

.react-select-custom__placeholder {
  font-size: 13px;
  color: #6b6b6b;
  font-weight: 300;
}

.react-select-custom__single-value {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 400;
}

.react-select-custom__option {
  font-size: 13px !important;
  color: #4a4a4a !important;
  font-weight: 400 !important;
}

.react-select-custom__option--is-focused {
  background-color: #f5f5f5 !important;
}

.react-select-custom__option--is-selected {
  color: #000000 !important;
  background-color: white !important;
  font-weight: 600 !important;
}

.react-select-custom__indicator {
  color: hsl(206.4deg 20.49% 52.16%);
}

.react-select-custom__control--is-focused .react-select-custom__indicator {
  color: var(--primaryColor);
}

.react-select-container-custom:focus-visible {
  outline: none;
  box-shadow: none;
}

.react-select-custom__value-container :focus-visible {
  outline: none;
  box-shadow: none;
}

.selectWrap {
  margin: 5px 0px 15px;
  position: relative;
}

#pieFirst {
  /* position: relative;
  top: -7px; */
}

.sortLbl {
  padding: 8px 15px;
}

.sortLbl:hover {
  background: #f9fafb;
  color: #000000;
}

.sortWarp {
  padding: 5px 0px !important;
}

.btn-disable-pop-filter {
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
}

.fund-form-wrap {
  position: relative;
  width: 550px;
  margin: 0 auto;
}

.custom-btn {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.custom-btn .btn-text {
  margin-left: 8px;
}

.space-left {
  margin-left: 0.7rem;
}

.blue-text {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 8px;
  color: #0284c7;
  cursor: pointer;
  display: flex;
  justify-content: center !important;
  background-color: 0.4s ease;
  transition: background-color 0.4s ease;
}

.blue-text:hover {
  background-color: var(--bgGray);
}

.blue-text-reference {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 8px;
  color: #0284c7;
  cursor: pointer;
  display: flex;
  background-color: 0.4s ease;
  transition: background-color 0.4s ease;
}

.blue-text-reference:hover {
  background-color: var(--bgGray);
}

.footer-list {
  list-style-type: disc;
  /* Use bullets for the list */
  padding-left: 20px;
  /* Add some padding for indentation */
}

.footer-list li {
  margin-bottom: 5px;
  /* Space between list items */
}

.rev-add-btn {
  color: #0284c7;
  font-weight: 600;
  padding: 10px 15px;
  cursor: pointer;
  width: 165px;
  display: flex;
  justify-content: center !important;
}

.rev-add-btn-primary {
  color: #ffffff;
  border-radius: 6%;
  font-weight: 600;
  z-index: 1;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #0284c7;
  width: 165px;
  display: flex;
  justify-content: center !important;
}

.rev-add-btn-primary-fund {
  color: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  z-index: 1;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: Inter;
  background-color: #0284c7;
  width: 136px;
  display: flex;
  justify-content: center !important;
}

.rev-add-btn-primary-custom-header {
  color: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  /* z-index: 1; */
  font-size: 14px;

  padding: 10px 15px;
  cursor: pointer;
  font-family: Inter;
  background-color: #0284c7;
  /* width: 136px; */
  /* display: flex;
  justify-content: center !important; */
}

.smaller-btn {
  --bs-btn-padding-x: .5rem;
  --bs-btn-padding-y: .15rem;
}

.blue-btn {
  --bs-btn-bg: var(--blue);
  --bs-btn-color: var(--white);
}

.blue-btn-reprocess {
  --bs-btn-bg: var(--blue);
  --bs-btn-color: var(--white);
}

.blue-btn-inverse {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--blue);
}

.add-action-item-button {
  border-radius: 5px;
  border-width: 1px;
  border: 1px solid;
  border-color: var(--body-background-color);
  width: 7rem;
  height: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.custom-btn.editBtun {
  font-weight: 500;
  border: 2px solid #d0d5db !important;
  border-radius: 13px;
  color: rgb(77, 77, 77);
  padding: 8px 15px;
}

.custom-btn.cancelBtn {
  font-weight: 500;
  border: 0px solid #d0d5db !important;
  border-radius: 13px;
  color: rgb(77, 77, 77);
  padding: 8px 15px;
}

.custom-btn.saveNewBtn {
  font-weight: 500;
  border: 0px solid #d0d5db !important;
  border-radius: 13px;
  color: rgb(0, 134, 199);
  padding: 8px 15px;
}

.custom-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.custom-btn:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.editBtn {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
  margin: 0 0px;
  width: 70px;
  transition: all 0.5s;
  height: 30px;
  cursor: pointer;
}

.editBtn:hover {
  /* background-color: var(--blue);
  color: white; */
}

.editBtn:hover .txtBtn {
  /* color: white; */
}

.editBtn:hover svg {
  /* color: white; */
}

.editBtn svg {
  font-size: 17px;
  position: relative;
  left: 5px;
}

.form-icon svg {
  font-size: 25px;
  padding: 0px 4px;
  margin-right: 2px;
  position: relative;
  left: -2px;
}

.form-section {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.fm-subtxt {
  font-size: 12px;
  font-weight: 400;
  color: #464646;
}

.fm-lbl {
  font-size: 12px;
  font-weight: 500;
  color: var(--textPrimary);
  margin-bottom: 5px;
  text-transform: unset;
}

.form-section.fund-form-section.fund-per-full-width {
  display: block;
}

.form-section.fund-form-section.fund-per-full-width .fee-header * {
  font-size: 13px;
}

.form-section .flexBTW {
  padding: 7px 15px;
  border-top: 1px solid #f1f1f1;
  margin-bottom: 5px;
  cursor: pointer;
}

.form-section .flexBTW:nth-child(1),
.form-section .flexBTW:nth-child(2),
.form-section .flexBTW:nth-child(3) {
  border-top: 0px;
}

.fund-edit .form-section .flexBTW:nth-child(3) {
  border-top: 1px solid #f1f1f1;
}

.form-section .flexBTW:last-child {
  border-bottom: none;
  margin-bottom: 2px;
}

.formInput {
  height: 27px;
}

.form-footer {
  position: absolute;
  bottom: 3px;
  width: 98%;
  left: 1px;
  padding: 12px;
  border-radius: 0px 0px 4px 4px;
}

.footerPrimaryBtn {
  background-color: var(--blue);
  border: 1px solid lightgray;
  color: var(--white);
  margin: 0 0px;
  width: 150px;
  transition: all 0.5s;
  height: 35px;
  box-shadow: 2px 2px 2px lightgray;
}

.footerSecondaryBtn {
  background-color: #e0f2fe;
  border: 1px solid #e0f2fe;
  color: var(--white);
  margin: 0 0px;
  width: 150px;
  transition: all 0.5s;
  height: 35px;
  box-shadow: 2px 2px 2px lightgray;
}

.footerSecontxtBtn {
  font-size: 11px;
  font-weight: 500;
  color: #0369a1;
  padding: 0px 10px;
  position: relative;
  top: 1px;
}

.footertxtBtn {
  font-size: 11px;
  font-weight: 500;
  color: var(--white);
  padding: 0px 10px;
  position: relative;
  top: 0px;
}

.saveBtn {
  background-color: #e0f2fe;
  border: 1px solid #e0f2fe;
  color: var(--white);
  margin: 0 0px;
  width: 75px;
  transition: all 0.5s;
  height: 30px;
  box-shadow: 2px 2px 2px #d3d3d33d;
}

.saveBtn svg {
  color: #0369a1;
}

.saveTxt {
  font-size: 11px;
  font-weight: 500;
  color: #0369a1;
  padding: 0px 10px;
  position: relative;
  top: 1px;
}

.linkBtn {
  border: none !important;
  box-shadow: none !important;
}

/* 16-may */
.fund-cntr-bx {
  width: 490px;
  height: calc(100vh - 400px);
}

.sml-txt {
  color: var(--textSecondary);
  margin: 0px 5px;
  cursor: pointer;
}

.fund-sub-txt {
  color: var(--textTertiary);
  font-size: 12px;
  font-weight: 400;
}

.fund-txt-fnt {
  padding-right: 20px;
  font-size: 11px;
  margin: 15px 12px;
  gap: 20px;
}

.fund-table {
  margin-top: 6px;
  padding-top: 6px;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 11px 0px 0px;
}

.FunddocBtn {
  /* background-color: var(--white); */
  border: 1px solid lightgray;
  color: var(--textPrimary);
  width: 137px;
}

/* 
.scroll-overSrn {
  overflow: auto;
  height: calc(100vh - 160px);
} */
.main-block {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
}

.fund-right-bx {
  width: 500px;
  height: calc(100vh - 400px);
}

.Side-NAV-lbl {
  font-size: 14px;
  font-weight: 500;
  color: var(--txtPlaceholder);
  margin: 16px 0px;
}

.Side-NAV-lbl-active {
  font-size: 14px;
  font-weight: 500;
  color: var(--textPrimary);
  padding: 8px 2px;
  margin: 8px 0px;
  background: #f3f4f6;
  border-radius: 10%;
}

.fund-cntr-bx-collapse {
  width: 575px;
  height: calc(100vh - 400px);
}

.fund-right-bx-collapse {
  width: 575px;
  height: calc(100vh - 400px);
}

.table-header {
  color: white;
  background: #4b5563;
  border: 2px solid #4b5563;
  border-radius: 20px 20px 0px 0px;
}

.vertical-line {
  border-left: 1px solid #e6e6e6;
  height: 20px;
  position: relative;
  top: 1px;
  margin: 0px 4px;
}

.vertical-line-thick {
  border-left: 2px solid var(--navBarColor);
  height: 70%;
  position: relative;
  top: 1px;
  margin: 0px 4px;
}

.fund-header {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 15px;
}

.fund-header-bx {
  margin: 5px 5px -10px;
  padding: 10px 10px 7px;
}

.fund-header-Opn-Doc {
  font-size: 12px;
  font-weight: 500;
  color: var(--textSecondary);
  padding: 0px 10px;
  cursor: pointer;
}

.Notes-header {
  color: #111827;
  font-weight: 500;
  font-size: 17px;
}

.note-date {
  margin-left: auto;
  color: var(--txtPlaceholder);
  font-size: 12px;
}

.note-drawer-divider {
  width: 100%;
  background-color: var(--navBarColor);
  height: 1px;
  margin: 16px 0;
}

.doc-lbl-up {
  font-size: 12px;
  color: #6b7280;
  font-weight: 300;
  padding: 3px 10px;
}

.customTable {
  border-spacing: 0;
  border: 1px solid #ededed;
  border-radius: 10px 10px 0px 0px;
  border-collapse: unset;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

/* .table-wrapper { */
/* overflow-x: auto; */
/* Allow horizontal scroll */
/* width: 100%; */
/* } */

.customTable th {
  background-color: #f9fafb;
  color: #374151;
  font-size: 14px;
  font-weight: 500;
}

.padd-table td {
  padding: 0px 0.6rem !important;
}

.customTable td {
  font-size: 13px;
  color: #111827;
  font-weight: 400;
  padding: 0.6rem;
  cursor: pointer;
}

.customTable th,
.customTable td {
  border-bottom: 1px solid #ededed;
  border-right: none;
}

.paginationWrp {
  background: #f9fafb;
  padding: 8px 12px;
  width: 100%;
  bottom: 0;
  border: 1px solid #e5e7eb;
  border-top: none;
  border-left: none;
  border-right: none;
}

.paginationRow {
  vertical-align: bottom;
}

.pager-container {
  height: 100%;
}

.paginationWrp .active {
  background: #0284c7 !important;
  border-radius: 50% !important;
  height: 27px !important;
  width: 27px !important;
  color: white !important;
  border: none;
  font-size: 11px !important;
  padding: 0px !important;
  line-height: 29px;
}

.pg-num {
  border-radius: 50% !important;
  height: 27px !important;
  width: 27px !important;
  color: var(--textPrimary) !important;
  border: none;
  font-size: 11px !important;
  padding: 6px !important;
  font-weight: 500 !important;
}

.prv-btn {
  font-size: 14px;
  color: #374151;
  font-weight: 500;
  background: none;
  border: none;
}

.prv-btn-dis {
  font-size: 14px;
  color: #d1d5db;
  outline: none;
  border: none;
  font-weight: 400;
}

.nxt-btn {
  font-size: 14px;
  color: #374151;
  font-weight: 500;
  background: none;
  border: none;
}

.nxt-btn-dis {
  font-size: 14px;
  color: #d1d5db;
  outline: none;
  border: none;
  font-weight: 400;
}

.pg-icon-dis {
  position: relative;
  top: -1px;
  height: 20px;
  width: 20px;
}

.pg-icon-dis {
  color: #d1d5db !important;
}

.pg-icon {
  position: relative;
  top: -1px;
  height: 20px;
  width: 20px;
}

.table-container {
  width: 100%;
  position: relative;
  border: 1px solid #e5e7eb;
  border-top: none;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  z-index: 5;
}

.dashboard-flexBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.scrollable-x {
  overflow-x: auto;
}

.scrollable::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 2px;
}

.scrollable::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 2px;
}

.table-container-notif {
  width: 100%;
  position: relative;
  height: calc(100vh - 475px);
  border: 1px solid #e5e7eb;
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
}

.scrollable-notif {
  height: calc(100vh - 475px);
  overflow-y: auto;
}

Table thead {
  position: sticky;
  top: -3px;
  margin: 0 0 0 0;
  z-index: 99;
}

.filter-rw-name {
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  font-weight: 500;
  color: #111827;
}

.FW-6 {
  font-weight: 600;
}

.filterBtn .txtBtn {
  min-width: 58px;
  padding: 0px;
}

.Side-NAV-width {
  width: 13%;
}

.Side-NAV-width-collapse {
  /* width: 5%; */
  width: 100px;
}

.fund-secd-width {
  width: 86%;
}

.fund-secd-width-collapse {
  width: 95%;
}

.fund-snd-cntr {
  display: flex;
  width: 95%;
}

.fund-secd-inner-width {
  display: flex;
  width: 100%;
  gap: 10px;
}

.fund-snd-sub-cntr-Y {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fund-snd-sub-cntr-X {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.fund-center-bx-plus {
  width: 100%;
}

/* 
.fund-center-bx-minus {
  width: 50%;
} */

.line-bar-graph {
  font-size: 17px;
  font-weight: 400;
  color: #374151;
}

.fund-active-tab {
  width: 100%;
  height: calc(100vh - 172px);
}

.Toastify__toast {
  background-color: #ecfdf5 !important;
  border: 1px solid #a7f3d0 !important;
  border-radius: 5px !important;
}

.NotifyBar {
  background: #ecfdf5;
  border: 1px solid #a7f3d0;
  padding: 15px;
  margin: 15px auto 0px;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 5%;
  transform: translateX(-50%);
  left: 50%;
  z-index: 999;
  width: 100%;
  max-width: 600px;
  box-shadow: 1px 1px 11px 0px #000000bd;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #6b7280;
}

.noti-txt {
  font-size: 13px;
  color: #4b5563;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noti-txt svg {
  margin: 0px 7px;
  position: relative;
  top: -0.5px;
}

.noti-span {
  font-size: 13px;
  color: #1f2937;
  font-weight: 600;
  cursor: pointer;
}

.noti-arr svg path {
  stroke: #1f2937;
}

.Side-NAV-width {
  /* width: 13%; */
  /* transition: width 1s; */
}

.Side-NAV-width-collapse {
  /* width: 5%; */
  /* transition: width 1s; */
}

.fund-secd-width {
  width: 86%;
  /* transition: width 1s; */
}

.fund-secd-width-collapse {
  width: 95%;
  /* transition: width 1s; */
}

.fund-snd-cntr {
  display: flex;
  width: 95%;
}

.fund-secd-inner-width {
  display: flex;
  width: 100%;
}

.fund-snd-sub-cntr-Y {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.fund-snd-sub-cntr-X {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.fund-center-bx-plus {
  width: 100%;
}

.fund-center-bx-minus {
  width: 50%;
  /*height: 100%;*/
}

.fund-right-bx-black-book {
  width: 40%;
}

.tabs-font-header {
  font-size: 16px;
  font-weight: 500;
}

.line-bar-graph {
  font-size: 13px;
  font-weight: 400;
  color: #374151;
}

.fund-active-tab {
  width: 100%;
  height: calc(100vh - 172px);
}

.dash-wrap {
  background-color: var(--white);
  border: 1px solid lightgray;
  padding: 15px;
  padding-bottom: 2rem;
  border-radius: 8px;
  /* height: calc(100vh - 600px); */
}

.bar-wrap {
  display: block;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

.dotted-line {
  border-bottom: 1px dotted var(--gray);
  /* Dotted line */
  border-color: var(--gray);
  border-radius: 5;
  border-style: 'dashed';
  border-width: 1px;
  width: 95%;
  /* Full width */
  margin: 2px 0px;
  /* Space above and below the line */
}

.pie-wrap {
  display: flex;
  align-content: center;
  justify-content: center;
}

.noteWrap {
  width: 450px !important;
}

.note-bth-save {
  background-color: #0284c7;
  border: 1px solid #0284c7;
  color: #fff;
  width: 65px;
  border-radius: 5%;
}

.note-bth-cancel {
  background-color: var(--white);
  border: 1px solid lightgray;
  color: var(--textPrimary);
  width: 71px;
}

.note-header-txt {
  font-size: 14px;
  font-weight: 400;
}

.Side-NAV-text {
  font-size: 13px;
  font-weight: 500;
  color: var(--textSecondary);
  padding-left: 4px;
  position: relative;
  top: 1px;
  display: block;
  width: 140px;
}

.dash-wrap-black-bk {
  background-color: var(--white);
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 4px;
}

.fund-tab-scroll {
  /*overflow-y: auto;
  overflow-x: hidden;*/
  /* height: calc(100vh - 666px); */
}

.fund-3-bx {
  margin-top: 3px;
  padding-top: 3px;
}

.activo a {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  background: #f3f4f6 !important;
  color: white !important;
}

.activo a span {
  color: #111827 !important;
}

.activo svg {
  color: #111827 !important;
  height: 2em;
  width: 2em;
}

.full-screen-mode-w-h {
  width: 100%;
  height: calc(100vh - 172px);
}

.w-black-book {
  width: 100%;
}

.pie-count-wrap {
  display: flex;
  background: #f9fafb;
  padding: 5px 5px 5px 5px;
  margin: 0px 0px 0px 0px;
  border-radius: 16px;
  font-size: 12px;
  position: relative;
}

.pie-filter-wrap {
  display: flex;
  background: #f9fafb;
  padding: 5px 40px 5px 5px;
  margin: 0px 10px 0px 0px;
  border-radius: 16px;
  font-size: 12px;
  position: relative;
}

.pie-filter-wrap div:last-child .icon {
  display: none;
}

.fil-close {
  position: absolute;
  right: 14px;
  top: 2px;
  font-size: 16px;
  cursor: pointer;
  background: #ebebeb;
  border-radius: 50%;
  height: 23px;
  width: 23px;
}

.fil-close:hover {
  background: #c9c9c9;
}

.fil-close svg {
  position: relative;
  left: 3px;
  top: -2px;
}

.icon {
  margin: 0px 5px;
}

.muiIcon {
  height: 13px !important;
  width: 13px !important;
}

.iconRow {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconRow>*:not(:last-child) {
  margin-right: 5px;
}

.sortIC {
  font-size: 13px;
  margin: 0px 15px;
  position: relative;
  top: 0px;
}

.sortIC:hover {
  color: #000;
}

.closemenu {
  position: absolute;
  right: -17px;
  z-index: 99;
  line-height: 20px;
  font-weight: bold;
  font-size: 22px;
  top: 40px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid lightgray;
  color: #6b7280;
}

.closemenu-drawer {
  position: absolute;
  right: 498px;
  z-index: 99;
  line-height: 20px;
  font-weight: bold;
  font-size: 22px;
  top: 40px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid lightgray;
  color: #6b7280;
}

.hidden {
  display: none !important;
}

.closemenu-blackBook {
  position: absolute;
  z-index: 99;
  left: -12px;
  top: 108px;
  line-height: 20px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid lightgray;
  color: #6b7280;
}

.closemenu-collapse {
  position: absolute;
  right: 0px;
  z-index: 99;
  line-height: 20px;
  font-weight: bold;
  font-size: 22px;
  top: 40px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid lightgray;
  color: #6b7280;
}

.activo svg path {
  stroke: #3d3d3d !important;
  height: 2em;
  width: 2em;
}

.activo .fillSvg svg path {
  height: 2em;
  width: 2em;
  fill: #3d3d3d !important;
  stroke: none !important;
}

.fund-dtl-bck {
  background: #f9fafb;
  margin: 15px 0px 0px;
  padding: 1px 20px;
  border-radius: 4px;
}

.horizontal-line {
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: baseline;
}

.spaceLeft {
  margin-left: 0.25rem;
}

.flex-spaced-between {
  display: flex;
  justify-content: space-between;
}

.Gray-circle {
  background: #f3f4f6;
  border-radius: 50%;
  padding: 8px;
}

.fund-perfom {
  /* height: calc(100vh - 670px); */
  overflow: hidden;
  height: 100%;
}

.fund-full-mde-h {
  height: calc(100vh - 245px) !important;
}

.table-sort-icon {
  cursor: pointer;
}

/* 27may */
/* .fund-wrap {
  background-color: var(--white);
  border: 2px solid #e5e7eb !important;
  height: calc(100vh - 600px);
  padding: 15px;
  border-radius: 4px;
}

.fund-wrap-full-screen {
  background-color: var(--white);
  border: 2px solid #e5e7eb !important;
  padding: 15px;
  border-radius: 4px;
} */

.filterView {
  padding-bottom: 10px;
  height: 40px;
}

.sideMenu {
  border-radius: 5px;
  border: 2px solid #e5e7eb !important;
  margin: 8px;
  background: #fff;
}

.sideMenu>div {
  border-radius: 5px;
  background-color: #fff;
}

.menuIcon svg {
  height: 19px;
}

.sideMenu .ps-menu-button {
  padding: 10px 4px !important;
  margin: 5px 4px;
  height: initial !important;
  border-radius: 4px;
}

.sideMenu .ps-menu-button:hover .Side-NAV-text {
  color: #111827 !important;
}

.flexCEN {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-head-menu-icon img {
  height: auto;
  width: 15px;
}

.sub-head-menu-icon img:last-child {
  position: relative;
  top: -1px;
}

.table-container-fund {
  width: 100%;
  position: relative;
  height: 90%;
  /* border: 1px solid #e5e7eb; */
  border-top: none;
  /* border-radius: 4px; */

  border-radius: 10px 10px 0 0;
  overflow: hidden;
  white-space: pre-wrap;
}

.blackbook-new {
  overflow: auto;
}

.blackbook-new table tbody tr td>span.cell {
  display: block;
  display: -webkit-box;
  height: 55px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container-fund-team {
  width: 100%;
  position: relative;
  height: 90%;
  z-index: 1;
  /* border: 1px solid #e5e7eb; */
  border-top: none;
  /* border-radius: 4px; */

  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

/* .table-container-fund .customTable,
.table-container-fund .customTable tr,
.table-container-fund .customTable th {
  border: none !important;
  border-radius: 0 !important;
}

.table-container-fund-team .customTable,
.table-container-fund-team .customTable tr,
.table-container-fund-team .customTable th {
  border: none !important;
  border-radius: 0 !important;
} */

.scrollable-fund {
  /* height: calc(100vh - 430px); */
  /* height: 90%; */
  overflow-y: auto;
}

.table-container-fund .customTable thead {
  background-color: #4b5563;
}

.table-container-fund-team .customTable thead {
  background-color: #4b5563;
}

.table-container-fund .customTable th {
  background-color: transparent;
  font-size: 13px;
  line-height: 24px;
  border: none;
  color: #fff;
  padding: 18px 12px;
  height: 60px;
  text-transform: capitalize !important;
}

.table-container-fund-team .customTable th {
  background-color: transparent;
  font-size: 13px;
  line-height: 24px;
  border: none;
  color: #fff;
  padding: 18px 12px;
  height: 60px;
  text-transform: capitalize !important;
}

.table-container-fund-team .customTable th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.table-container-fund-team .customTable th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.table-container-fund-team .customTable th:last-child {
  border-radius: 0px 10px 0px 0px;
}

.progressIndustry>div {
  height: 13px;
  background: rgb(224, 224, 222);
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}

.sub-head-menu-icon {
  margin-bottom: 5px;
}

.assetGraph {
  /* top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%); */
}

.graphCenter {
  height: 100%;
  margin: auto 0px;
  align-items: center;
}

.port-title {
  color: #111827;
  font-size: 13px;
  font-weight: 500;
}

.up-container {
  padding: 10px;
  padding-top: 0px;
}

.up-wrap {
  width: 100%;
  margin-bottom: 145px;
  height: 100%;
}

.bottom-spacer {
  height: 300px;
}

.P-15 {
  padding: 15px;
}

.W-33 {
  width: 33%;
}

.H-30rem {
  height: 30rem;
}

.ReactModalPortal>div {
  position: fixed !important;
  inset: 0px !important;
  background-color: rgb(0 0 0 / 54%) !important;
  z-index: 999;
}

.ReactModalPortal>div>div {
  border-radius: 7px !important;
}

.skip-review {
  background: #dc2626;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #fbfbfb;
}

.submit-email {
  background: #0284c7;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #fbfbfb;
}

.done-modal {
  background: #0284c7;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #fbfbfb;
}

.doneModel-action .buttonStyle {
  width: auto;
  padding: 9px 17px;
  height: auto;
}

.doneModel-action .buttonStyle .txtStyle {
  padding: 0px;
}

.review-txt {
  color: #fff;
}

.review-cancel {
  height: 35px;
  border-radius: 4px;
  padding: 0px 45px;
}

.fund-form-section {
  padding: 10px;
  gap: 10px;
  display: block;
}

.fund-form-section.form-section .fee-block.flexBTW {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 12px !important;
  max-width: unset;
  border-radius: 10px 10px 0 0;
  margin: 0px;
  /* height: 60px; */
}

.fee-block .fee-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 35%;
  max-width: 35%;
}

.fee-block .fee-delete-button {
  background: white;
  color: #6b7280;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid lightgray;
}

.fee-block .fee-delete-button svg {
  padding: 4px;
}

.fee-block .fm-lbl {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin: 0px;
  color: #fff;
}

.fee-block .fee-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 37%;
  max-width: 37%;
}

.rev-add-button-block {
  box-shadow: 2px 2px 4px #ddd;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: table;
  margin-top: 10px;
}

.rev-add-button-block-custom-header {
  box-shadow: 2px 2px 4px #343434;
  border-radius: 10px;
  border: 1px solid #4877aa;
  display: table;
  margin-left: 36px;
}

.fund-edit .form-section {
  display: flex;
  flex-wrap: wrap;
}

.fund-edit .form-section .flexBTW {
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
}

.form-section.fund-form-section .flexBTW svg {
  width: 20px;
  height: 20px;
}

.form-section .flexBTW {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  align-items: center;
}

.breadcrumb {
  list-style: none;
  margin: 0px !important;
}

.breadcrumb li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.breadcrumb li+li:before {
  content: '';
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin: 0px 8px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #111827;
}

.breadcrumb li:last-child a {
  font-weight: 600;
}

.upload-document-header {
  margin-left: 0.05rem;
}

.red-text {
  color: var(--textSecondary);
  font-size: 14px;
  margin-bottom: 0;
}

.radio-reg-text {
  margin-left: 0.5rem;
}

.fundSelection-flex {
  display: flex;
  gap: 3rem;
  min-height: 60px;
}

.radio-text-flex {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

.radio-header-text {
  margin-bottom: 10px;
}

.dropdown-container {
  font-family: 'Inter', sans-serif !important;
  font-size: 13px;
  position: relative;
  display: inline-block;
  padding-bottom: 0px;
}

.dropdown-title {
  font-weight: 550;
  margin-bottom: 5px;
  margin-top: 0px;
  padding-top: 0px;
}

.dropdown-trigger {
  border-radius: 5px;
  height: 2rem;
  width: 16rem;
  overflow: hidden;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-trigger-text {
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.emptySpace {
  width: 1rem;
}

.emptySpaceVertical {
  height: 3rem;
}

.emptySpaceVerticalSmall {
  height: .5rem;
}

.send-newStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sendStatus-widget {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #8bff48;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.sendStatus-widget:hover {
  background-color: #7be13f;
}

.initialReview-widget {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fc4639;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.initialReview-widget:hover {
  background-color: #ff1616;
}

.fundLabel-checkmark-wrapper {
  display: flex;
}

.widget {
  align-self: center;
}

.fundLabel-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

.fundDocument-wrapper {
  display: inline-block;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.fundDocument-trigger {
  overflow: hidden;
  width: 7.5rem;
  justify-content: space-between;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 0.25rem 0.8rem 0.4rem;
  cursor: pointer;
}

.fundDropdown-trigger {
  overflow: hidden;
  width: 7.5rem;
  border-radius: 16px;
  justify-content: space-between;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 5px 5px 5px 5px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}

.fundDropdown-text {
  font-size: 1em;
}

.fundDropdown-menu-auto {
  height: auto !important;
}

.fundDropdown-menu {
  position: absolute;
  height: 8rem;
  /* Limits height to 10rem */
  overflow-y: auto;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notesDropdown-menu {
  position: absolute;
  height: 8rem;
  /* Limits height to 10rem */
  overflow-y: auto;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notesDropdown-width {
  width: 40%;
}

.fundDropdown-status-menu {
  position: absolute;
  overflow-y: auto;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.fundDropdown-menu:hover {}

.fundDropdown-menuEntry {}

.fundDropdown-menuEntry:hover {
  background-color: #00ac69;
}

.instructional-text {
  font-size: 11px;
  line-height: 1.9;
  color: var(--textTertiary);
  font-style: italic;
}

.document-spacing {
  margin-left: 8px;
}

.trailing-icon {
  flex-shrink: 0;
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% - 5px);
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex !important;
  flex-direction: column;
}

.menuItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}

.menuItem:hover {
  background-color: #f0f0f0;
}

.menuItem:last-child {
  border-bottom: none;
}

/* 
.fund-custom-h {
  height: calc(100vh - 420px) !important;
} */

.fund-details {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.more-btn {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}


.fund-txt-fnt .col {
  flex: 0 0 32.33%;
  max-width: 32.33%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
}

.fund-form-section .fee-block.fee-header {
  background: #4b5563;
}

/* custom table css */
.fund-single-all .table-container-fund .customTable tbody,
.fund-single-all .table-container-fund .customTable thead {
  display: table-caption;
  width: 100%;
}

.fund-single-all .table-container-fund .customTable tbody tr,
.fund-single-all .table-container-fund .customTable thead tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.fund-single-all .table-container-fund .customTable tr td {
  border-bottom: 0px;
}

.fund-single-all .table-container-fund .customTable tr td:first-child,
.fund-single-all .table-container-fund .customTable tr td:nth-child(2) {
  width: 50%;
}

.fund-single-all .table-container-fund .customTable th:first-child,
.fund-single-all .table-container-fund .customTable th:nth-child(2) {
  width: 50% !important;
  text-transform: uppercase;
}

.fund-single-all .table-container-fund .customTable th:nth-child(3) {
  width: 42px !important;
}

.fund-txt-fnt span {
  word-break: break-all;
}

.fund-dtl-bck .fund-txt-fnt span:first-child {
  color: var(--textTertiary);
  /* text-transform: uppercase; */
  font-weight: 380;
}

.fund-dtl-bck .fund-txt-fnt span:last-child {
  color: var(--textPrimary);
  font-weight: 480;
}

.form-section .form-section-wrapper {
  width: 100%;
}

.fee-block .fee-value>div,
.fee-block .fee-input>div {
  width: 100%;
}

#react-modal .review-cancel>div {
  color: black;
}

.edit-fee .fee-block.flexBTW {
  align-items: baseline;
  gap: 10px;
}

.edit-fee .fee-block .fee-value {
  flex: unset;
  width: 100%;
}

.fee-edit-icon {
  display: flex;
  gap: 5px;
  flex: 0 0 10% !important;
  max-width: 10% !important;
  justify-content: end;
  align-items: center;
}

.edit-fund .table-container-fund .customTable tr td:first-child,
.edit-fund .table-container-fund .customTable tr td:nth-child(2) {
  width: auto;
}

.table-width .table-container-fund .customTable tr td {
  width: 426.055px;
}

.edit-fund .table-container-fund .customTable th:first-child,
.edit-fund .table-container-fund .customTable th:nth-child(2) {
  width: auto !important;
}

.edit-fund .table-container-fund-team .customTable th:first-child,
.edit-fund .table-container-fund-team .customTable th:nth-child(2) {
  min-width: 175px !important;
}

.edit-fund .table-container-fund .customTable tbody,
.edit-fund .table-container-fund .customTable thead {
  display: table-header-group;
}

.edit-fund .table-container-fund .customTable tbody tr,
.edit-fund .table-container-fund .customTable thead tr {
  display: table-row;
}

.edit-fund .table-container-fund-team .customTable tr td:first-child,
.edit-fund .table-container-fund-team .customTable tr td:nth-child(2) {
  width: auto;
}

/* .table-width .table-container-fund-team .customTable tr td {
  width: 426.055px;
} */

.edit-fund .table-container-fund-team .customTable tbody,
.edit-fund .table-container-fund-team .customTable thead {
  display: table-header-group;
}

.edit-fund .table-container-fund-team .customTable tbody tr,
.edit-fund .table-container-fund-team .customTable thead tr {
  display: table-row;
}

.fund-full-width .fee-block .fee-value {
  flex: unset;
  display: block;
}

.fund-full-width .fee-block .fee-value .formInput {
  width: 100%;
}

.fund-full-width .fee-block .fee-value>div,
.fund-full-width .fee-block .fee-input>div {
  max-width: 100%;
}

.fund-full-width .fee-block {
  gap: 10px;
}

.half-width {
  width: 50% !important;
}

.full-blacbook-width {
  width: 100% !important;
}

.fund-full-width .edit-portfolio-block .fee-value {
  width: 100%;
}

.table-row-ellipse .table-container-fund tr td span:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  line-height: 40px;
}

.fund-form-section.form-section .flexBTW.documentTableDiv .formInput {
  height: unset;
}

.fund-form-section.form-section .flexBTW.documentTableDiv {
  height: 60px !important;
  padding: 0px !important;
  align-items: center;
}

.fund-form-section.form-section .flexBTW.documentTableDiv .table-header {
  background: transparent;
  border: none;
  height: unset;
}

.document-fee-block .fee-value .fee-input .fm-subtxt {
  font-weight: 500;
  font-size: 13px;
}

.fund-form-section.form-section .flexBTW.documentTableDiv>div {
  padding: 18px 12px;
}

.documentTableDiv>div {
  padding: 18px 8px !important;
}

/* .table-container-fund thead,
.fund-form-section.form-section .fee-block.fee-header {
  height: 45px !important;
} */
.table-container-fund-team .customTable th:before {
  right: 0;
  top: 40%;
  width: 1px !important;
  height: 14.3945px;
  background: #181d1f;
  opacity: 0.2;
  border: none;
}

.fund-single-all .table-container-fund .scrollable-fund .customTable th {
  width: 150px !important;
}

/* .table-row-ellipse .table-container-fund {
  height: 88%;
  overflow: auto;
} */

/* For error messages in add document */
.error-box {
  margin: 3px;
  margin-left: 10px;
  display: inline-block;
  background-color: #ffebee;
  color: #e53935;
  border: 1px solid #e53935;
  border-radius: 8px;
  padding: 10px;
}

.table-row-ellipse .table-container-fund .scrollable-fund {
  height: unset;
}

/* 
.scroll-overSrn {
  overflow: auto;
  height: calc(100vh - 160px);
} */

.fix-height {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.fix-height table thead {
  position: sticky;
  top: 0;
}

.portfolio-full-width .fee-block .fee-value,
.portfolio-full-width .fee-block .fee-input {
  width: 100%;
}

.fund-document-table .scrollable-fund tr td span {
  word-break: break-all;
  display: block !important;
  overflow: unset !important;
  height: unset !important;
  line-height: unset !important;
}

.fund-field-edited {
  margin-left: 3px;
  background: #f3f4f6;
  border-radius: 10px;
  width: 57px;
  height: 20px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px #0000000d;
  line-height: 16px;
  gap: 10px;
  padding: 2px 1px;
  display: inline-block;
}

.fund-field-edited .edited-text {
  font-family: Inter;
  color: #1f2937;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.fund-field-score-matrix {
  margin-left: 3px;
  background: #f3f4f6;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px #0000000d;
  line-height: 16px;
  gap: 10px;
  padding: 2px 1px;
  display: inline-block;
}

.fund-field-score-matrix .tooltip-text {
  font-family: Inter;
  color: #1f2937;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  cursor: default;
}

.all-pages-button {
  margin-top: 10px;
}

.dashboard-layout {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.asd {
  height: 500px;
}

.dashboard-layout .table-container-fund .customTable th {
  font-size: 12px;
  padding: 0px 10px;
  height: 45px;
}

.dashboard-layout .table-container-fund thead {
  height: 45px !important;
}

.portfolio-companies-table-view .table-container-fund .customTable tr th:first-child,
.portfolio-companies-table-view .table-container-fund .customTable tr th:nth-child(2),
.portfolio-companies-table-view .table-container-fund .customTable tr th:nth-child(3) {
  width: 100px !important;
}

.portfolio-companies-table-view .table-container-fund .customTable tr td:first-child,
.portfolio-companies-table-view .table-container-fund .customTable tr td:nth-child(2),
.portfolio-companies-table-view .table-container-fund .customTable tr td:nth-child(3) {
  width: 400px;
}

.documentTableDiv .table-header {
  width: 150px;
}

.documentTableDiv .table-header:first-child,
.documentTableDiv .table-header:nth-child(2),
.documentTableDiv .table-header:nth-child(3) {
  width: 200px;
}

.fund-form-section.form-section .document-fee-block {
  padding: 10px 0px !important;
}

.fund-form-section.form-section .document-fee-block .fee-value {
  width: 150px;
}

.fund-form-section.form-section .document-fee-block .fee-value:first-child,
.fund-form-section.form-section .document-fee-block .fee-value:nth-child(2),
.fund-form-section.form-section .document-fee-block .fee-value:nth-child(3) {
  width: 200px;
}

.fund-table-block .table-container-fund .scrollable-fund .customTable th,
.fund-table-block .table-container-fund .scrollable-fund .customTable td {
  width: 150px !important;
}

.fund-table-block .table-container-fund .scrollable-fund .customTable th:nth-child(2),
.fund-table-block .table-container-fund .scrollable-fund .customTable th:nth-child(3),
.fund-table-block .table-container-fund .scrollable-fund .customTable th:first-child,
.fund-table-block .table-container-fund .scrollable-fund .customTable td:nth-child(2),
.fund-table-block .table-container-fund .scrollable-fund .customTable td:nth-child(3),
.fund-table-block .table-container-fund .scrollable-fund .customTable td:first-child {
  width: 200px !important;
}

.form-section.form-esg-section {
  display: block;
}

.form-section.form-esg-section .flexBTW {
  max-width: 100%;
  padding: 15px;
}

.form-section.form-esg-section .flexBTW:nth-child(2),
.form-section.form-esg-section .flexBTW:nth-child(3) {
  border-top: 1px solid #f1f1f1;
}

.form-section.form-esg-section .flexBTW .form-section-wrapper {
  display: table;
  table-layout: fixed;
}

.form-section.form-esg-section .flexBTW .form-section-wrapper>div {
  display: table-cell;
  vertical-align: middle;
}

.form-section.form-esg-section .flexBTW .form-section-wrapper>.fm-subtxt p {
  margin: 0px;
  word-break: break-all;
}

.fund-edit .form-section.form-esg-section .flexBTW {
  flex: 0 0 100%;
  max-width: 100%;
}

/* For DDChecklist Components */
.DD-font {
  font-size: 11px;
  font-weight: 500;
  color: var(--navBarText);
}

.bold {
  color: var(--textTertiary);
  font-weight: 700;
  font-size: 12px;
}

.scroll-button {
  z-index: 999;
  cursor: pointer;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: var(--gray);
}

.scroll-button:hover {
  color: var(--btn-primary);
}

.inactive-default {
  cursor: default;
  color: var(--bgGray);
}

.stages-container-wrapper {
  display: flex;
  flex-direction: row;
}

.stages-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  z-index: 1;
  scroll-behavior: smooth;
  user-select: none;
}

.edit-stages-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.2rem;
  z-index: 1;
  scroll-behavior: smooth;
  user-select: none;
}

.stage-item {
  text-align: left;
  cursor: pointer;
  padding-right: 1.5rem;
}

.stage-item:last-child {
  padding-right: 0;
}

.stage-line {
  height: var(--radius);
  border-radius: 10px;
  background-color: var(--bgGray);
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.stage-item.active .stage-line {
  background-color: var(--blue);
}

.stage-name {
  font-size: 16px;
  transform: scaleY(0.95);
  margin-bottom: 0px;
  color: var(--textTertiary);
  transition: background-color 0.3s ease;
}

.stage-item.active .stage-name {
  color: var(--blue);
}

.input-remove-row {
  display: flex;
}

.stage-text {
  white-space: nowrap;
  font-size: 14px;
  transform: scaleY(0.95);
  margin-bottom: 0px;
  color: var(--textTertiary);
}

.dd-wrapper {}

/*.accordion-content {
  display: flex;
  justify-content: center;
}*/

.header-table {
  width: 100%;
  table-layout: fixed;
  padding: 10px;
  padding-bottom: 0px;
  font-weight: bold;
  text-align: center;
  border: none;
}

.DD-table-container {
  width: 100%;
}

.DD-cell,
.header-column {
  text-align: left;
  border: none;
  width: 280px;
}

.Scoring-Res-Metrix,
.header-column {
  text-align: left;
  border: none;
  width: 85px;
}

.header-column .tooltips {
  margin-left: 5px;
}

.DD-cell-narrow,
.header-column-narrow {
  text-align: left;
  justify-content: flex-start;
  border: none;
  width: 100px;
}

.DD-cell-extra-narrow,
.header-column-extra-narrow {
  text-align: left;
  justify-content: flex-start;
  border: none;
  width: 50px;
}

.DD-cell-rem,
.header-column-rem {
  text-align: left;
  justify-content: flex-start;
  border: none;
  width: 30px;
}

.header-column.smallest {
  text-align: left;
  justify-content: flex-start;
  border: none;
  width: 65px;
}

.DD-cell-medium,
.header-column-medium {
  text-align: left;
  justify-content: flex-start;
  border: none;
  width: 150px;
}

.header-column {
  background-color: var(--navBarColor);
  color: var(--txtPlaceholder);
  padding-left: 1.3rem;
  text-align: center;
}

.header-column-narrow {
  padding-left: 0.8rem;
  background-color: var(--navBarColor);
  color: var(--txtPlaceholder);
  text-align: center;
}

.header-column-medium {
  padding-left: 0.2rem;
  background-color: var(--navBarColor);
  color: var(--txtPlaceholder);
  text-align: center;
}

.header-column-extra-narrow {
  background-color: var(--navBarColor);
  color: var(--txtPlaceholder);
  text-align: center;
}

.textarea-container {
  position: relative;
  display: inline-block;
  width: 95%;
}

.benchmarkCommentInput {
  width: 100%;
  min-width: 75px;
}

.custom-header {
  display: flex;
}

.footer-value {
  display: flex;
  /* Use flexbox for alignment */
  justify-content: center;
  /* Center the text horizontally */
  align-items: center;
  /* Center the text vertically */
  background-color: var(--txtPlaceholder);
  /* Gray background */
  color: white;
  /* White text for contrast */
  border-radius: 50%;
  /* Make it circular */
  width: 1.2rem;
  /* Set the width */
  height: 1.2rem;
  /* Set the height */
  font-size: 0.8rem;
  /* Smaller font size */
  margin-left: 5px;
  /* Space from the header text */
  line-height: 1;
  /* Ensure no extra spacing in the line */
}

.footer-value-pdf {
  display: flex;
  /* Use flexbox for alignment */
  justify-content: center;
  /* Center the text horizontally */
  align-items: center;
  /* Center the text vertically */
  /*background-color: var(--txtPlaceholder); /* Gray background */
  color: white;
  /* White text for contrast */
  border-radius: 50%;
  /* Make it circular */
  width: 20px;
  /* Set the width */
  height: 20px;
  /* Set the height */
  font-size: 10px;
  /* Smaller font size */
  margin-left: 5px;
  /* Space from the header text */
  line-height: 1;
  /* Ensure no extra spacing in the line */
}

.loading-icon,
.checkmark-icon-comment {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  background: none;
}

.loading-icon-lead,
.checkmark-icon-lead {
  width: 10%;
  height: 10%;
  background: none;
}

.lead-text {
  width: 90%;
}

.values-flex {
  display: flex;
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--btn-avtaar-round);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
}

.commentTextArea {
  width: 100%;
  height: 100%;
}

.borderFocus {
  border: none;
  outline: none;
  resize: none;
}

.borderFocus:focus {
  outline: auto;
  outline-color: var(--textTertiary);
}

.padRight {
  padding-right: 0.2rem;
}

.headerGroup {
  width: 100%;
  flex: 1;
}

.profilePicture {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.users-dropdown {
  position: absolute;
  top: 100%;
  margin-top: -0.5rem;
  left: 0;
  z-index: 1000;
  background-color: var(--bgAccent);
  border: 1px solid var(--bgGray);
  border-radius: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 4px;
  min-width: 4rem;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5%;
  margin-bottom: 8px;
}

.personComponent {
  display: inline-block;
  border-radius: 10%;
  background-color: var(--bgGray);
}

.personComponent.padding {
  padding: 0.35rem;
}

.clickable {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.padding10px {
  padding: 10px;
}

.unselectable {
  user-select: none;
}

.dropdownArrow {
  margin-right: 1rem;
  cursor: pointer;
}

.comment-icon {
  cursor: pointer;
}

.height100 {
  height: 100%;
}

.width25 {
  width: calc(25% - 10px);
}

.move-down {
  margin-top: 0.2rem;
}

.underneath-actionItem {
  text-align: left;
}

.action-item {
  margin-right: 0.5rem;
}

.action-item:last-child {
  margin-right: 0;
}

.action-item input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.action-item input[type='checkbox']:checked {
  /*background-color: #007bff;
  border-color: #007bff;*/
}

.action-item input[type='checkbox']:disabled {
  cursor: default;
}

.customCheckbox {
  display: inline-block;
  position: relative;
}

.action-item-text-input {
  width: 15rem;
}

.stage-text-input {
  width: 10rem;
  display: inline;
}

.remove-item {}

.remove-action-button {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  margin-left: 0rem;
  margin-right: 0.1rem;
  justify-content: center;
  align-items: center;
  color: var(--blue);
  border: none;
  background-color: var(--bgAccent);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 35%;
  transition: background-color 0.3s ease;
}

.remove-action-button:hover {
  background-color: var(--bgGray);
  box-shadow: 2px 2px 10px rgba(0, 0, 0.1, 0.2);
}

.remove-action-button:disabled {
  color: var(--gray);
  border-color: var(--lightGray);
  background-color: var(--bgLightGray);
  cursor: not-allowed;
}

.remove-action-button:disabled:hover {
  background-color: var(--bgLightGray);
  box-shadow: none;
}

.smaller {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.6rem;
}

.add-process-button {
  border-radius: 5px;
  background-color: var(--white);
  border: 0;
  padding: 0.3rem 0.6rem;
  color: var(--blue);
  font-weight: 550;
  transition: background-color 0.4s ease;
}

.add-process-button.align {
  margin-left: 3rem !important;
}

.add-process-button:hover {
  background-color: var(--bgGray);
}

.dotted {
  border: 1px dotted;
}

.checkmark-icon {
  position: relative;
  left: -1rem;
  top: -0.5rem;
  pointer-events: none;
}

.checkmark-icon-subheader {
  position: relative;
  left: -1rem;
  top: -0.5rem;
  pointer-events: none;
}

.dd-color {
  background-color: var(--blue);
  transition: background-color 0.3s ease;
}

.dd-color:hover {
  background-color: var(--btn-avtaar-round);
}

.dd-add-color {
  background-color: var(--btn-tertiary);
  transition: background-color 0.3s ease;
}

.dd-add-color:hover {
  background-color: var(--btn-tertiary-hover);
}

.add-stage-button {
  width: 3rem;
  height: 3rem;
  border-radius: 40%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.bookmark-icon {
  font-size: 24px;
  color: white;
}

/* */

.edited-icon {
  width: 10%;
  text-align: right;
}

.edited-icon-table.fund-single-all .table-container-fund .customTable tr td:last-child {
  text-align: right;
}

.edited-icon-table.fund-single-all .table-container-fund .customTable tr td:last-child,
.edited-icon-table.fund-single-all .table-container-fund .scrollable-fund .customTable tr th:last-child {
  flex: 0 0 10% !important;
  max-width: 10% !important;
}

.edited-icon-table.fund-single-all .table-container-fund .scrollable-fund .customTable th,
.edited-icon-table.fund-single-all .table-container-fund .scrollable-fund .customTable td {
  flex: 0 0 37% !important;
  max-width: 37% !important;
}

.tooltips {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.tooltips .tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: #555555e6;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  min-width: 120px;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltips .tooltiptext2 {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
}

.tooltips:hover .tooltiptext2 {
  visibility: visible;
}

.tooltip-top {
  top: 135%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top2 {
  bottom: 115%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.fund-performance-container,
.fund-benchmarks-container {
  overflow: unset;
}

/* .fee-page .table-container-fund {
  overflow: unset;
} */

.switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch.on {
  background-color: #2196f3;
}

.switch-handle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.switch.on .switch-handle {
  transform: translateX(25px);
}

.switch.disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.switch.disabled .switch-handle {
  background-color: #f0f0f0;
}

.table-container-new {
  overflow-x: auto;
  padding: 10px;
  background-color: #f8f9fa;
  /* Adjust as needed */
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.custom-table thead tr {
  background-color: #f5f5f5;
}

.custom-table th,
.custom-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
}

.custom-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.darker .ag-theme-quartz {
  --ag-header-cell-moving-background-color: #485563 !important;
}

.darker .ag-header {
  background: #485563 !important;
}

.darker .ag-theme-quartz .ag-header-row {
  background: #4b5563;
  color: #fff !important;
}

.ag-theme-quartz.white-text .ag-header-cell-label {
  background: #4b5563;
  color: #fff !important;
}

.ag-theme-quartz.white-text .custom-header {
  background: #4b5563;
  color: #fff !important;
}

.ag-theme-quartz.white-text .ag-header-cell-comp-wrapper {
  background: #4b5563;
  color: #fff !important;
}

.ag-theme-quartz.paginationUI1 .ag-paging-page-size {
  display: none;
}

.ag-theme-quartz.paginationUI1 .ag-paging-row-summary-panel {
  justify-items: flex-start;
}

/*.ag-theme-quartz.paginationUI1 .ag-paging-row-summary-panel span:not(:nth-child(5)) {
  display: none;
}*/

/*.ag-theme-quartz.paginationUI1 .ag-paging-row-summary-panel span:nth-child(5)::before {
  content: "Total Items: " "\00a0";
}*/

.ag-theme-quartz.paginationUI1 [aria-disabled="true"] {
  opacity: 0.4;
}

.ag-theme-quartz.paginationUI1 [aria-disabled="false"] {
  opacity: 1.2;
}

.ag-theme-quartz.paginationUI1 .ag-paging-page-summary-panel {
  margin: auto;
  transform: translateX(calc(50% - 85%));
}

.ag-theme-quartz.paginationUI1 .ag-paging-panel {
  justify-content: center;
  display: flex;
  align-items: center;
}

.ag-theme-quartz.paginationUI1 .ag-paging-description span:not(:nth-child(2)) {
  display: none;
}

.ag-theme-quartz.paginationUI1 .ag-paging-description span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  font-size: small;
  transition: color 0.4s ease;
  user-select: none;
  background-color: #3498db;
  /* Blue color for active */
  color: var(--bgGray);
}

.ag-theme-quartz.white-text .ag-header-cell-resize::after {
  background-color: #fff !important;
  /* Replace with your desired color */
}

#tool-tip {
  z-index: 999;
}

.custom-tooltip {
  z-index: 999 !important;
  position: absolute !important;
  /* width: 300px !important; */
  /* Set the fixed width */
  max-width: 300px;
  /* Optional, ensures it doesn't grow beyond this */
  word-wrap: break-word;
  /* Allows text to wrap */
  white-space: normal;
  /* Ensure content wraps down */
  padding: 10px;
  /* Add some padding for better readability */
  background-color: #333;
  /* Optional: Customize background color */
  color: #fff;
  /* Optional: Customize text color */
  border-radius: 5px;
  /* Optional: Rounded corners */
  text-align: left;
  /* Optional: Align text to the left */
}

.gray {
  background-color: #7dd3fc;
}

.reference-modal .form-field {
  margin-bottom: 1.5rem;
  /* Adjust spacing as needed */
}

.reference-modal .label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  /* Add spacing between label and field */
  display: block;
}

.flex-container {
  display: flex;
  align-items: center;
  /* Vertically align items */
  justify-content: space-between;
  /* Space between left and right sections */
}

.left-content {
  display: flex;
  align-items: center;
  /* Vertically align icon and title */
  gap: 0.5rem;
  /* Add spacing between the icon and the title */
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tooltips {
  padding: 5px;
  background-color: #5577cc;
}

.custom-tooltips p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltips p:first-of-type {
  font-weight: bold;
}

.ag-cell {
  line-height: 1.7 !important;
  padding: 7px 0px;
}

.fund-name {
  font-weight: bold;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: vertical;
}

h2.custom-h2 {
  font-size: 12px;
}

/* .ag-cell ul li, .ag-cell ol li{
  line-height:1!important;
} */

.documentList .flexRow {
  margin: 15px 0px;
  gap: 15px;
}

.documentList .upload-box {
  margin: 0px;
  padding: 10px;
  width: 75%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.documentList .inputStyle {
  padding: 10px;
  height: inherit;
}

.documentList .upload-box .uploadTxt {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.documentList .inputStyle .fundDropdown-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chatReply p {
  margin: 0px;
}

.fundname-tooltip .react-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-width: 90% !important;
  padding: 4px !important;
  line-height: 14px !important;
}

.compare-header-class .ag-header-cell-comp-wrapper {
  justify-content: center !important;
}

.compare-header-class .ag-header-cell-comp-wrapper .rev-add-button-block-custom-header {
  margin-left: 10px !important;
}

.compare-cell-class .ag-cell-wrapper {
  text-align: center !important;
}

/*fund compare header sticky*/
.compatre-page .main-block {
  overflow-y: hidden !important;
}

.compare-tablescroll .ag-header {
  position: sticky !important;
  top: 0px !important;
  z-index: 10 !important;
}

.compare-tablescroll .ag-body-viewport.ag-layout-auto-height {
  max-height: 400px !important;
  overflow: scroll !important;
}

.compare-tablescroll .ag-body-viewport.ag-layout-auto-height::-webkit-scrollbar {
  width: 8px !important;
  /* Width of the scrollbar */
  height: 8px !important;
  /* Height of horizontal scrollbar */
}

.compare-tablescroll .ag-body-viewport.ag-layout-auto-height::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  border-radius: 6px !important;
}

.compare-tablescroll .ag-body-viewport.ag-layout-auto-height::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* portfolio*/
.folioLinkModal {
  .person-view-container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }

  .section {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    color: #3498db;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  a:hover {
    text-decoration: underline;
  }
}

/* CompanyDetails.css */
.CompanyDetails {
  .container {
    display: flex;
    flex-direction: column;
    padding: 1em;
    font-family: Arial, sans-serif;
  }

  .noData {
    color: #999;
    font-style: italic;
  }

  .fund_highlights {
    width: 100%;
  }

  .fund_highlights.parent {
    border: 1px solid lightgray;
  }

  .fund_highlights.parent .fund_highlights_title {
    border-width: 0px 0px 1px 0px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 0.5px;
  }

  .fund_highlights_website {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 0.5px;
    border-bottom: 1px solid rgb(236, 0, 0);
    width: 100%;
  }

  .fund_highlights_title {
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  .fund_highlights_social {
    display: flex;
    color: var(--textPrimary);
    align-items: center;
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .fund_highlights_social>svg {
    margin-left: 8px;
    font-size: 16px;
  }

  .fund_highlights_title_content {
    cursor: pointer;
    display: flex;
    color: #374151;
    align-items: center;
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .fund_highlights_title_content>svg {
    margin-right: 8px;
    font-size: 20px;
    color: #1D1B20;
  }

  .fund_highlights_title a {
    font-size: 12px;
    font-weight: 400;
    display: block;
    color: #0284C7;
    letter-spacing: 0.5px;
  }

  .fund_highlights_items {
    padding: 5px 16px;
    background-color: #f1f9ff;
    margin-bottom: 3px;
  }

  .fund_highlights_items a {
    font-size: 14px;
    display: block;
    color: #6b6b6b;
  }

  .fund_highlights_title:hover {
    cursor: pointer;
  }
}

.singlefund {
  padding: 0px !important;
  height: auto !important;
}

.singlefund.flex-1 {
  flex: 1;
}

.singlefund .form-section-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  border-bottom: 1px solid #eee;
}

.singlefund p {
  margin: 0px;
}

.singlefund .fm-lbl {
  min-width: 150px;
  max-width: 150px;
}

.recommendation-amount-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}


.recommendationForm {
  padding: 0px !important;
}

.recommendationForm.flex-1 {
  flex: 1;
}

.recommendationForm .form-section-wrapper {
  align-items: center;
  padding: 15px 15px;
}

.recommendationForm p {
  margin: 0px;
}

.recommendationForm .fm-lbl {
  min-width: 150px;
  max-width: 150px;
}

.commited-form-row {
  align-items: self-end;
  padding: 7px 0px;
}

.commited-form-row>div:nth-child(2),
.commited-form-row>div:nth-child(4) {
  min-width: 100px;
}

.commited-input {
  height: 40px;
  padding: 11px 10px;
}

.commited-amount-wrap {
  align-items: baseline;
}

.commited-amount-wrap>div:first-child,
.commited-amount-wrap>div:last-child {
  min-width: 90px;
}

.commit-title {
  z-index: 9999;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  background-color: #E5E7EB;
  padding: 15px;
}

.commit-title .left-content .title2 {
  margin: 0px;
}

.commit-title .delete-close {
  right: 0px;
  top: 8px;
}

.factbox-item {
  box-shadow: 0px 1px 2px 0px #0000000D;
  background-color: #F9FAFB;
  border-radius: 8px;
  padding: 32px 12px;
  border: 1px solid #E5E7EB;
  text-align: center;
}

.commit-title+.recommendation-component {
  padding-top: 44px;
}

.rmsc .dropdown-container {
  width: 100%;
}

.rmsc .gray {
  background-color: #ffffff
}

.ag-popup-child {
  z-index: 10 !important;
}

.fund-doc-table .ag-root-wrapper,
.fund-doc-table .ag-root-wrapper .ag-layout-normal {
  border-bottom: none !important;
}

.fund-doc-table .ag-header {
  top: -3px !important;
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.fund-doc-table .ag-icon-desc,
.fund-doc-table .ag-icon-asc,
.fund-doc-table .ag-icon-filter {
  color: rgba(255, 255, 255, 0.50) !important;
}

.fund-doc-table .ag-root-wrapper-body.ag-layout-normal {
  height: 100%;
}

.fund-doc-table .ag-body {
  min-height: 100px;
}

.fund-doc-table .ag-root-wrapper {
  overflow: inherit;
}

.fund-doc-table .ag-root.ag-layout-normal {
  overflow: inherit;
}

.fund-closing-date-column {
  padding: 0px !important;
  border: none !important;
}

.fund-closing-date-column .MuiInputBase-input {
  height: auto !important;
  border-radius: 4px;
  padding: 9px 8px !important;
}

.agCustomTable .EditIcon {
  opacity: 0;
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d4d4d4;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #d4d4d4;
}

.agCustomTable .EditIcon.CompareIcon {
  right: 5px;
}

.agCustomTable .ag-row:hover .EditIcon {
  opacity: 1;
}

.agCustomTable .ag-row:hover .EditIcon:hover,
.agCustomTable .ag-row:hover .EditIcon:focus {
  background-color: #d4d4d4;
  border: 1px solid #999;
}

.fund-closing-date-column .closing-date-cell {
  min-height: 39px;
}

.fund-datepicker {
  z-index: 11 !important;
}


.fund-compare-table-scroll .ag-cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 100%;
  vertical-align: middle;
  /* Adjust as needed */
}

.fund-compare-table-scroll .ag-cell p {
  margin: 0px;
}

.select-height .react-select-custom__control {
  min-height: 52.40px;
}

.associated-table {
  display: block !important;
}

.company-menu {
  position: absolute;
  left: 0px;
  right: 0;
  width: 100%;
  z-index: 999;
}

.dashboard-table .ag-header {
  top: -15px !important;
}

.dashboard-table .ag-header {
  background-color: var(--ag-header-background-color);
  border-bottom: var(--ag-borders-critical) var(--ag-border-color);
}

.ag-theme-quartz.white-text .dashboard-table .ag-header-cell-label,
.ag-theme-quartz.white-text .dashboard-table .ag-header-cell-comp-wrapper {
  background: transparent !important;
  color: var(--ag-header-foreground-color) !important;
}

.dashboard-table .ag-icon-desc,
.dashboard-table .ag-icon-asc,
.dashboard-table .ag-icon-filter {
  color: var(--ag-header-foreground-color) !important;
}

.ag-theme-quartz.white-text .dashboard-table .ag-header-cell-resize::after {
  background-color: color-mix(in srgb, #0000, var(--ag-foreground-color) 15%) !important;
}

.equal-height-table {
  height: calc(100% - 60px);
}

.equal-height-table .compare-tablescroll {
  height: 100% !important;
  min-height: 300px;
}