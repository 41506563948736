table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #00000094;
}

.ph-item {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  margin-bottom: 0px;
  padding: 0 0 0px 0;
  overflow: hidden;
  direction: ltr;
  background-color: #fff;
  border-radius: 2px;
}

.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
  box-sizing: border-box;
}
.ph-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
  -webkit-animation: phAnimation 0.9s linear infinite;
  animation: phAnimation 0.9s linear infinite;
  content: ' ';
  pointer-events: none;
}
.ph-item > * {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  margin-bottom: 5px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
}
.ph-row div {
  height: 10px;
  margin-top: 5px;
  border-radius: 2px;
  background: linear-gradient(
    to right,
    rgba(196, 196, 196, 0.7),
    rgba(196, 196, 196, 0.2)
  );
}

.grid-alignCenterHeader {
  font-size: 20px;
  align-self: center;
}

.ph-row .empty {
  background: #fff;
}
.ph-col-1 {
  /* flex: 0 0 100%; */
  width: 70%;
}
.no-data-text {
  height: 100%;
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-10%, 0, 0);
  }
  100% {
    transform: translate3d(10%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-10%, 0, 0);
  }
  100% {
    transform: translate3d(10%, 0, 0);
  }
}

.loader-row:hover {
  background: none !important;
}
