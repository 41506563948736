@media (max-width: 992px) {
  body {
    overflow-x: auto;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: auto;
  }
}

@media (max-width: 650px) {
}
