.bar-container {
    overflow-x: auto; /* Enables horizontal scrolling */
    max-width: 800px; /* Set your preferred max width */
  }
  
  .chart-scroll {
    min-width: 100px; /* Prevents chart from squeezing */
    width: auto; /* Expands if needed */
    display: inline-block; /* Keeps it from breaking layout */
  }

  .bar-wrap.chart-wrap {
    overflow: auto;
  }
  