div.input-block {
  position: relative;
}

div.input-block input {
  font-size: 1.1rem;
  color: #1e1f20;
  width: 100%;
  padding: 4px 15px;
  border-radius: 4px;
  border: 1.2px solid #d9d9d9;
  outline: none;
}

div.input-block span.placeholder {
  border-radius: 3px;
  position: absolute;
  margin: 8px 0;
  padding: 0 0px;
  color: #6c757d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9rem;
  top: 0;
  left: 12px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

div.input-block span.placeholder.required {
  color: var(--btn-tertiary);
}

div.input-block input:valid + span.placeholder,
div.input-block input:focus + span.placeholder {
  transform: scale(0.8) translateY(-20px);
  background: #fff;
  padding: 0px 5px;
}

div.input-block input:focus {
  color: #1e1f20;
  border-color: var(--btn-tertiary);
}

div.input-block input:focus + span.placeholder {
  color: var(--btn-tertiary);
}

.placeholder {
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #2d2d2d;
  position: relative;
  top: -1px;
}
