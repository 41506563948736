/* Details Page CSS  */

/* 27may */
.fund-wrap {
  background-color: var(--white);
  height: 98%;
  border: 2px solid #e5e7eb !important;
  /* height: 350px; */
  /* height: calc(100vh - 350px); */
  padding: 15px;
  border-radius: 4px;
}

.fund-wrap-full-screen {
  background-color: var(--white);
  border: 2px solid #e5e7eb !important;
  padding: 15px;
  border-radius: 4px;
}

svg.icon.injected-svg {
  margin: 0px;
}

.fund-edit-w {
  width: 70%;
  margin: 0 auto;
}

.wider-box {
  width: 90%;
}

.edit-review-details {
  gap: 10px;
}

.edit-review-details .me-1,
.edit-review-details .fund-edit {
  width: 50%;
}

.fund-edit-full-width {
  width: 100% !important;
}

.fund-pdf {
  width: 95%;
  transition: width 0.3s ease;
}

.fund-edit-pdf {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.validation-message {
  color: red;
}

.edit-form-blackbook {
  position: relative;
  background: white;
  /* height: calc(100vh - 140px); */
  margin: 20px auto !important;
  border-radius: 7px;
  border: 1px solid lightgray;
  box-shadow: 0px -1px 4px 0px #ebebeb;
  padding: 10px;
  /* overflow: auto; */
  /* padding-bottom: 55px; */
  /*   */
}

.edit-form {
  background: white;
  /* height: calc(100vh - 140px); */
  margin: 20px auto !important;
  border-radius: 7px;
  border: 1px solid lightgray;
  box-shadow: 0px -1px 4px 0px #ebebeb;
  padding: 10px;
  /* overflow: auto; */
  /* padding-bottom: 55px; */
  /*   */
}

.edit-form-pdf {
  height: unset;
}

.edit-form-pdf .rpv-core__inner-pages {
  overflow: auto;
  height: 65vh !important;
}

.accordion {
  border: 1px solid lightgray;
  margin-top: 1rem;
  border-radius: 4px;
}

.itemBtn {
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  text-align: left;
  color: #111927;
  background-color: #ffffff !important;
  /* border: 1px solid lightgray; */
  border-radius: 4px;
  z-index: 1;
}

.itemBtn:hover {
  /* background-color: #f3f4f6 !important; */
  cursor: pointer;
}

.itemBtnExpanded {
  background-color: #f9fafb !important;
  /* height: 73px; */
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  padding: 1rem;
}

/* 28may */

.isEditChecked {
  fill: green !important;
  height: 1em !important;
  width: 1em !important;
}

.edit-display-none {
  display: none;
}

.checkSvg {
  height: 1em !important;
  width: 1em !important;
  position: absolute;
  right: 10px;
}

.Side-NAV-width .ps-sidebar-container {
  height: unset;
}

.ps-sidebar-container ul > li {
  border-left: 4px solid transparent;
  margin-top: 5px;
}

.isActiveLeftMenu {
  border-left: 4px solid green !important;
  margin-top: 5px;
}

.menuLblHead {
  color: #111827;
  font-size: 15px;
  font-weight: 500;
}

.accordion {
  border: none !important;
}

.accordion-title {
  border-bottom: 1px solid #dddddd;
}

.Caution {
  background: #fee2e2;
  border-radius: 50%;
  padding: 8px;
}

.Caution-txt {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.Caution-sub-txt {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
  padding-bottom: 15px;
}

.fund-edit .form-section {
  display: flex;
  flex-wrap: wrap;
}

.fund-edit .form-section .flexBTW {
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
}

.form-section .flexBTW svg {
  width: 16px;
  height: 16px;
}

.form-section .flexBTW {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  align-items: center;
}

/* .form-section-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
} */

.drawer-container {
  overflow-y: unset !important;
}

.drawer-container.collapsed {
  max-width: 1%;
  overflow-y: unset !important;
  transition: 0.3s ease;
}

.fund-documents-table .dashboard-flexBox tr td:first-child span {
  word-break: break-all;
}

.fund-edit-w .fund-single-all .table-container-fund .customTable tr td,
.fund-edit-w .fund-single-all .table-container-fund .customTable tr th {
  width: 10% !important;
  padding: 10px 5px;
}

.fund-edit-w .fund-single-all .table-container-fund .customTable tr td span {
  word-break: break-all;
}

.fund-edit-w .edit-form-wrap .fee-block .fee-value {
  display: block;
  flex: unset;
  max-width: unset;
}

.fund-edit-w .edit-form-wrap .fee-block .fee-value .fee-input {
  width: 100%;
  max-width: unset;
}

.fund-edit-w .fund-form-section .fee-block.flexBTW {
  gap: 4px;
}

.half-width.fund-edit-width
  .form-section.fund-form-section.fund-per-full-width {
  overflow: auto;
}

.half-width.fund-edit-width
  .fund-single-all
  .table-container-fund
  .customTable
  th {
  width: 125px !important;
}

.half-width.fund-edit-width
  .fund-single-all
  .table-container-fund
  .customTable
  tr
  td {
  width: 125px !important;
}

.half-width.fund-edit-width .dashboard-flexBox > div {
  overflow: auto;
}

.half-width .fund-single-all .table-container-fund .customTable th {
  width: 125px !important;
}

.half-width .fund-single-all .table-container-fund .customTable tr td {
  width: 125px !important;
}

.half-width.fund-edit-width .dashboard-flexBox > div {
  overflow: auto;
}

.half-width.fund-edit-width .fund-form-section.form-section .fee-block.flexBTW {
  min-width: 1310px;
  gap: 5px;
}

.half-width.fund-edit-width
  .fund-form-section.form-section
  .fee-block.flexBTW
  .fee-value {
  display: block;
  flex: unset;
  max-width: unset;
}

.half-width.fund-edit-width
  .fund-form-section.form-section
  .fee-block.flexBTW
  .fee-value
  .fee-input {
  max-width: unset;
}

.fund-single-all > div {
  overflow: visible;
}

.fund-single-all table {
  border-radius: 10px 10px 0px 0px !important;
}

.benchmark-page-container {
  width: 90%;
}

.fee-header-action {
  flex: 0 0 10% !important;
  max-width: 10% !important;
  text-align: center !important;
}

.fee-edit-icon {
  justify-content: center;
}

.dashboard-flexBox-pdf th:first-child,
.dashboard-flexBox-pdf td:first-child {
  width: 10%;
}

.dashboard-flexBox-pdf th,
.dashboard-flexBox-pdf td {
  width: 100%;
}
.fund-compare-table {
  overflow: auto;
}
.fund-compare-table td span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  display: block;
}
.edit-form-document {
  background: white;
  margin-left: 20px !important;
  border-radius: 7px;
  border: 1px solid lightgray;
  box-shadow: 0px -1px 4px 0px #ebebeb;
  padding: 10px;
}

.document-table .ag-cell {
  line-height: 40px !important;
}
