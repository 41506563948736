@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

#root {
  --primary: #1f2937;
  --primaryAccent: #374151;
  --bgAccent: #f9fafb;
  --bgGray: #f3f4f6;
  --blue: #0284c7;
  --blueAccent: #e7f2f8;
  --white: #ffffff;
  --textPrimary: #111827;
  --textSecondary: #515a68;
  --textTertiary: #6b7280;
  --tableHead: #4b5563;

  --navBarColor: #cacccf;
  --navBarText: #1c1c1c;

  --pie1: #e0f2fe;
  --pie2: #075985;
  --pie3: #0284c7;
  --pie4: #0c4a6e;
  --pie5: #7dd3fc;

  --green: #00ac69;
  --red: #ff1616;
  --lighterRed: #ffa7a7;
  --gray: #9e9e9e;
  --vio: #800dad;
  --btn-avtaar-round: #659ce0;
  --btn-avtaar-round-color: #212121;
  --btn-primary: #35313e;
  --btn-secondary: #0074c1;
  --btn-tertiary: #009400;
  --btn-tertiary-hover: #108000;
  --btn-secondary-hover: #005c99;
  --head-main-label: #03a9f4;
  --header-main: #34495e;
  --header-secondary: #03a9f4;
  --radius: 4px;
  --buttonShadow: 1px 1px 1px #f0f0f0;
  --txtPlaceholder: #848484;
  --txtSecondary: #3d3d3d;
  --body-background-color: #e5f4ff;
}

.scrollable-page {
  height: 100vh;
  overflow-y: auto;
}

.scrollable-page::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 2px;
}

.scrollable-page::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.scrollable-page::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 2px;
}

body {
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Inter', sans-serif !important;
  font-size: 13px !important;
  overflow: hidden;
  background-color: #f9fafb !important;
  padding-top: 0px !important;
  height: 100vh;
}

html {
  background-color: #ececec !important;
}

.pacingHeader {
  background-color: var(--navBarText);
  color: var(--white);
}

.pacingRow {
  display: flex;
  width: 100%;
  align-items: start;
  padding-left: 5%;
  padding-right: 5%;
  gap: 5%;
  justify-content: space-between;
}

.pacingRow > * {
  flex-basis: 50%;
  box-sizing: border-box;
}

.pacingRowBlackbook {
  display: flex;
  /* width: 100%; */
  /* align-items: start; */
  /* padding-left: 5%;*/
  /* padding-right: 50%;  */
  gap: 5%;
  /* justify-content: space-between; */
}

.pacingRowBlackbook > * {
  flex-basis: 50%; /* Each child takes 50% of the width */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.pacingTable {
  width: 100%;
}

.middle {
  text-align: center;
}

.pacingTable th,
td {
  border-left: none !important;
  border-right: none !important;
  border-collapse: unset;
  border-bottom: 1px solid #ededed;
}

.pacingTable tbody tr:last-child {
  background-color: var(--bgGray);
}

.allocation-snapshot {
  display: flex;
  justify-content: center;
}

.total-row {
}

.pacing-item {
  border: solid 1px var(--bgGray);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pacing-title {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.fund-name {
  margin-right: 110px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: normal;
}
